import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import { setTitle, getAll, deleted } from '../../actions'
import Swal from 'sweetalert2'
import Button from '../../components/Button'
import MaterialTable from 'material-table'

const Users = props => {
  const { users, setTitle, localization, themeApp } = props

  useEffect(() => {
    const topbar = {
      title: 'Usuarios',
      buttonBar: (
        <Link to="/dashboard/users/create">
          <Button>Crear</Button>
        </Link>
      ),
    }
    setTitle(topbar)
    props.getAll('users', 'GET_USERS')
    // eslint-disable-next-line
  }, [])

  const handleDeleteUser = userId => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Este proceso no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
    }).then(result => {
      if (result.isConfirmed) {
        props.deleted(`users/${userId}`, 'DELETE_USER').then(() => {
          props.getAll('users', 'GET_USERS')
        })
        Swal.fire('Borrado!', 'Borrado con exito.', 'success')
      }
    })
  }

  return (
    <>
      <MaterialTable
        columns={[
          { title: 'Nombre', field: 'name' },
          { title: 'Usuario', field: 'user' },
          {
            title: 'Rol',
            field: 'role',
            lookup: { 1: 'Administrador', 2: 'Usuario' },
          },
          {
            title: 'Acciones',
            field: 'id',
            render: rowData =>
              rowData.id > 1 ? (
                <>
                  <Link to={`/dashboard/users/${rowData.id}`}>
                    <Button color="warning">
                      <AiOutlineEdit />
                    </Button>
                  </Link>
                  <Button
                    color="danger"
                    onClick={() => handleDeleteUser(rowData.id)}
                  >
                    <AiOutlineDelete />
                  </Button>
                </>
              ) : null,
            export: false,
          },
        ]}
        localization={localization}
        data={users}
        title="Lista de Usuarios"
        options={{
          pageSize: 50,
          pageSizeOptions: [50, 100, 150],
          emptyRowsWhenPaging: false,
          filterCellStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          headerStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          rowStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          actionsCellStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          searchFieldStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          detailPanelColumnStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
        }}
        style={{
          backgroundColor: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.backgroundButton
              : themeApp.darkTheme.backgroundButton
          }`,
          color: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.colorButtonCircle
              : themeApp.darkTheme.colorButtonCircle
          }`,
        }}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
