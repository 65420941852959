export default function reducerCustomers(state = {}, action) {
  switch (action.type) {
    case 'GET_CONTACT':
      return {
        ...state,
        contact: action.payload.data,
      }
    default:
      return state
  }
}
