import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { update, get, setTitle, getAll } from '../../actions'
import Card from '../../components/Card'
import Input from '../../components/Input'
import Button from '../../components/Button'
import styled from 'styled-components'

const CreateSupplier = props => {
  const [passwordVerify, setPasswordVerify] = useState(false)
  const [password, setPassword] = useState('')
  const [form, setForm] = useState({})
  const { setTitle, user } = props
  const { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    const topbar = {
      title: 'Editar Usuario',
    }
    setTitle(topbar)
    props.get(`users/${id}`, 'GET_USER')

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user) {
      setForm({
        user: user.user,
        name: user.name,
        role: user.role,
        password: '',
      })
    }
    // eslint-disable-next-line
  }, [user])

  const handlePasswordVerify = e => {
    if (password === e.target.value) {
      setPasswordVerify(true)
    } else {
      setPasswordVerify(false)
    }
  }

  const handleForm = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.update(`users/${id}`, 'POST_USER', form).then(() => {
      navigate('/dashboard/users')
    })
  }
  if (user) {
    return (
      <Card title={'Información del usuario'}>
        <form id="formUser" onSubmit={e => handleSubmit(e)}>
          <Input
            type="text"
            name="name"
            title="Nombre"
            onChange={e => handleForm(e)}
            defaultValue={user.name}
          />

          <Input
            type="text"
            name="user"
            title="Usuario"
            onChange={e => handleForm(e)}
            defaultValue={user.user}
          />
          <Input
            type="password"
            name="password"
            title="Contraseña"
            onChange={e => {
              handleForm(e)
              setPassword(e.target.value)
            }}
          />
          <Input
            type="password"
            name="confirmPassword"
            title="Confirmar Contraseña"
            onChange={e => {
              handlePasswordVerify(e)
            }}
          />

          {password !== '' ? (
            passwordVerify ? (
              <Span pass={passwordVerify}>Password Correcto</Span>
            ) : (
              <Span pass={passwordVerify}>
                Los password deben ser iguales
              </Span>
            )
          ) : null}

          <Select onChange={e => handleForm(e)} name="role" required>
            <option value={user.role}>Seleccione un rol</option>
            <option value="1">Administrador</option>
            <option value="2">Usuario</option>
          </Select>

          <BarButton>
            <Button type="submit" color="success">
              Editar
            </Button>
            <Link to="/dashboard/users">
              <Button className="btn --danger">Cancelar</Button>
            </Link>
          </BarButton>
        </form>
      </Card>
    )
  } else {
    return <h1>Cargando</h1>
  }
}

const mapStateToProps = state => {
  return {
    divisions: state.users.divisions,
    roles: state.users.roles,
    departments: state.users.departments,
    user: state.users.user,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  update,
  get,
  setTitle,
  getAll,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSupplier)

const Select = styled.select`
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  outline-color: ${({ theme }) => theme.border};
  color: var(--dark);
  background-color: var(--white);
  background-clip: padding-box;
  border-radius: 3px;
  border: ${({ theme }) => theme.border};
  box-shadow: none;
  margin-top: 0.375rem;
  border-style: solid;
  border-radius: 7px;
  width: 100%;
  transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`

const BarButton = styled.div`
  margin-top: 1rem;
`

const Span = styled.span`
  color: ${props =>
    props.pass ? props.theme.success : props.theme.danger};
  font-size: 0.875rem;
  line-height: 2.5;
`
